* {
  color: white;
  box-sizing: border-box;
}

body {
  background: black;
}

.flex {
  display: flex;
}

@media screen and (max-width: 992px) {
  .flex {
    display: block;
  }
}

.flex-column {
  flex-direction: column;
}

canvas {
  height: 100vh;
}

input, button {
  border: none;
  background: white;
  padding: 10px;
  color: black;
}

input {
  border-radius: 10px 0px 0px 10px;
  flex-grow: 2;
}

button {
  border-radius: 0px 10px 10px 0px;
  color: white;
}

.name {
  color: white;
  width: 120px;
  margin-left: 100px;
  background: none;
}

.chat {
  padding: 20px;
}

.chat > h2 {
  top: 0px;
  padding: 20px 0px 10px;
  margin: 0;
  position: sticky;
  background: black;
}

.window {
  padding-top: 0;
  overflow-y: scroll;
  max-height: 70vh;
  min-width: 20vw;
}

.users {
  overflow-y: scroll;
  max-height: 20vh;
}

.username {
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px;
  display: block;
}

.enter {
  height: 100vh;
  width:100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circle {
  width: 350px;
  height: 350px;
  background: #0f001d;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle > img {
  transition: margin 0.3s;
  animation: spin 20s linear infinite;
}

.circle > h1 {
  margin: 25px;
  font-size: 2rem;
}

.circle:hover > img {
  transform: translateY(20px)
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}